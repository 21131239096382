import * as React from 'react';
import * as styles from './quizzes.module.scss';

import { Link, graphql } from 'gatsby';

import Callout from '../components/v2/callout';
import Footer from '../components/v2/footer';
import Header from '../components/v2/header';
import SEO from '../components/seo';
import Section from '../components/section';
import TemplateCategoryIcon from '../components/template-category-icon';

const QuizzesPage = ({ data }) => {
  return (
    <>
      <SEO
        title="Take Quizzes"
        description="Fun & challenging quizzes across categories like health, lifestyle, business & more! Test your knowledge, explore interests & discover new passions."
      />
      <Header btnLabel="Make a quiz" btnLink="/" btnTheme="primary" btnType="internal" />
      <main className={styles.templateCategories}>
        <Section style={{ marginTop: `3rem` }}>
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8 text-center">
              <h1 className="heading-xl color-black" style={{ margin: `2rem 0` }}>
                Quiz Gallery
              </h1>
              <h2 className="heading-sm" style={{ marginBottom: '4rem' }}>
                Test your knowledge and explore your interests with quizzes across many categories!
                From health and lifestyle to business and finance, from fashion and beauty to travel
                and culture, there's a quiz for everyone. Discover new passions, improve your
                skills, or simply have fun testing your knowledge.
              </h2>
            </div>
          </div>
          <div className="row">
            {data.quizTemplateCategories.edges.map(({ node }) => {
              const { name, slug, count, icon } = node.data;
              return (
                <div
                  className="col-6 col-md-4 col-lg-3 col-xl-2"
                  style={{ marginBottom: `1.5rem` }}
                  key={slug}>
                  <Link to={`/quizzes/${slug}/`}>
                    <div className={styles.card}>
                      <TemplateCategoryIcon id={icon} />
                      <div className={styles.content}>
                        <h2 className="text-body color-black">{name}</h2>
                        <p>{count} quizzes</p>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </Section>
        <Callout />
      </main>
      <Footer />
    </>
  );
};

export default QuizzesPage;

export const query = graphql`
  query {
    quizTemplateCategories: allAirtable(
      filter: { table: { eq: "Categories" }, data: { active: { eq: true } } }
      sort: { fields: data___order }
    ) {
      edges {
        node {
          data {
            name
            slug
            count
            icon
          }
        }
      }
    }
  }
`;
